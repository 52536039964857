import React from 'react';
import { createUseStyles } from 'react-jss';
import { IconType } from 'react-icons';
import { Card } from '../../components/Card';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingLeft: '5rem',
    paddingRight: '5rem',
  },
  value: {
    margin: 0,
    padding: 0,
    fontSize: '12rem',
    display: 'flex',
    alignItems: 'flex-start',
  },
  unit: {
    fontSize: '4rem',
  },
});

interface ValueCardProps {
  value: string;
  unit?: string;
  icon: IconType;
}

export const ValueCard: React.FC<ValueCardProps> = ({
  icon: Icon,
  unit,
  value,
}) => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <Icon size="10rem" />
      <p className={classes.value}>
        {value}
        {unit && <span className={classes.unit}>{unit}</span>}
      </p>
    </Card>
  );
};
