import { API_URI } from '../../../utils/env';

export type ImageList = Array<string>;

export const getImageList = async (imageFolder: string): Promise<ImageList> => {
  const action = `/file-list/${imageFolder}`;
  const url = `${API_URI}${action}`;
  const response = await fetch(url, {
    method: 'GET',
  });

  return (await response.json()) as ImageList;
};
