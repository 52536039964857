import React from 'react';
import { createUseStyles } from 'react-jss';
import { Card } from '../../components/Card';

const useStyles = createUseStyles({
  head: {
    margin: '2rem 0 0 0',
    padding: 0,
    fontSize: '6rem',
  },
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface WelcomeCardProps {}

export const WelcomeCard: React.FC<WelcomeCardProps> = () => {
  const classes = useStyles();
  return (
    <Card title="Willkommen">
      <p className={classes.head}>Liebe Gäste</p>
    </Card>
  );
};
