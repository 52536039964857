import { useInterval } from 'beautiful-react-hooks';
import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback,
} from 'react';
import { WeatherData, getWeatherData } from '.';

type WeatherContextValue = {
  data?: WeatherData | null;
  error?: Error | null;
};

const WeatherContext = createContext<WeatherContextValue>({
  data: null,
  error: null,
});

export const useWeather = (): WeatherContextValue => {
  const { data, error } = useContext(WeatherContext);
  return {
    data,
    error,
  };
};

type WeatherProviderProps = {
  coordinates?: {
    lat: number;
    lon: number;
  };
  children?: React.ReactNode;
};

export const WeatherProvider: React.FC<WeatherProviderProps> = ({
  coordinates,
  children,
}) => {
  const [data, setWeatherData] = useState<WeatherData | undefined>();
  const [error, setError] = useState<Error | null>(null);

  const fetch = useCallback(() => {
    if (!coordinates) {
      setError(new Error('No Coordinates have been passed to WeatherProvider'));
      return;
    }
    getWeatherData(coordinates)
      .then((result) => setWeatherData(result))
      .catch((e) => {
        console.error(e);
        setError(e);
      });
  }, [coordinates]);

  // fetch new data every hour
  useInterval(fetch, 3600000);
  useEffect(() => fetch(), [fetch]);

  return (
    <WeatherContext.Provider
      value={{
        data,
        error,
      }}
    >
      {children}
    </WeatherContext.Provider>
  );
};
