import { API_URI } from '../utils/env';

export type SensorData = {
  value: number;
};
export const getSensorData = async (id: string): Promise<SensorData> => {
  const response = await fetch(`${API_URI}/capability/${id}`, {
    method: 'GET',
    headers: {
      Accept: '*/*',
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) throw new Error(response.statusText);
  return (await response.json()) as SensorData;
};

export type AggregatedData = {
  utcTimeMeasured: string;
  valueString: string;
};
export const getAggregatedData = async (
  id: string,
): Promise<AggregatedData[]> => {
  const response = await fetch(`${API_URI}/capability/${id}/aggregated`, {
    method: 'GET',
    headers: {
      Accept: '*/*',
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) throw new Error(response.statusText);
  return (await response.json()) as AggregatedData[];
};
