import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Carousel as ResponsiveCarousel } from 'react-responsive-carousel';
import { slideHeight, slideWidth } from '../../constants';

const useStyles = createUseStyles({
  root: {
    height: slideHeight,
    width: slideWidth,
  },
});

interface CarouselProps {
  children: JSX.Element[];
}

const defaultDuration = 5000;

export const Carousel: React.FC<CarouselProps> = ({ children }) => {
  const classes = useStyles();
  const [currentInterval, setCurrentInterval] = useState(1000);

  const onChange = (index: number, item: React.ReactNode) => {
    const i = item as { props?: { duration?: number } };
    setCurrentInterval(i?.props?.duration || defaultDuration);
  };
  return (
    <ResponsiveCarousel
      interval={currentInterval}
      onChange={onChange}
      className={classes.root}
      autoPlay
      infiniteLoop
      showArrows={false}
      showIndicators={false}
      showStatus={false}
      showThumbs={false}
      stopOnHover={false}
      axis="vertical"
      centerMode
      centerSlidePercentage={100}
    >
      {children}
    </ResponsiveCarousel>
  );
};
