import React from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { SunMoonCard, SunMoonData } from './SunMoonCard';
import {
  CurrentWeatherBasicCard,
  CurrentWeatherBasicData,
} from './CurrentWeatherBasicCard';
import { useWeather } from '../../api/weather-context';
// import { DailyForecastCard, DailyForecastData } from './DailyForecastCard';
import { padding, slideHeight, slideWidth } from '../../constants';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    height: slideHeight,
    width: slideWidth,
    alignItems: 'center',
    flexDirection: 'column',
    padding: '5vw 2vw',
    justifyContent: 'space-around',
  },
  row: {
    width: '100%',
    height: '60%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  topRow: {
    marginBottom: padding,
  },
});

type WeatherSlideProps = {};

export const WeatherSlide: React.FC<WeatherSlideProps> = () => {
  const classes = useStyles();
  const weatherData = useWeather();

  if (!weatherData?.data) return null;
  const { data } = weatherData;

  const { daily, main, weather, sys } = data;
  const today = daily?.[0];

  const currentWeatherBasicData: CurrentWeatherBasicData = {
    icon: weather?.[0]?.icon || '',
    text: weather?.[0]?.description || '',
    temperature: main?.temp || 0,
    humidity: main?.humidity || 0,
    precipitation: daily?.[0].pop || 0,
  };

  const sunMoonData: SunMoonData = {
    sunrise: sys?.sunrise || 0,
    sunset: sys?.sunset || 0,
    moonPhase: today?.moon_phase || 0,
  };

  /* const dailyForecast = [daily?.[1], daily?.[2]];
   const dailyForecastData = dailyForecast
    ?.map((d) => ({
      dt: d?.dt || 0,
      icon: d?.weather?.[0]?.icon || '',
      maxTemperature: d?.temp.max || 0,
      minTemperature: d?.temp.min || 0,
    }))
    .filter((d) => d) as DailyForecastData[]; */

  return (
    <div className={classes.root}>
      <div className={classNames(classes.row, classes.topRow)}>
        <CurrentWeatherBasicCard
          currentWeatherBasicData={currentWeatherBasicData}
        />
      </div>
      <div className={classes.row}>
        <SunMoonCard sunMoonData={sunMoonData} />
        {/* dailyForecastData.map((d) => (
          <DailyForecastCard dailyForecastData={d} key={d.dt} />
        )) */}
      </div>
    </div>
  );
};
