export enum Color {
  white = '#FFFFFF',
  black = '#000000',
  grey = '#969EAF',

  kpOrange = '#F07D00',
  b2bBlueDark = '#123067',
  b2bBlack = '#282D37',

  slideBackground = '#ECEDE8',

  airQualityGood = '#A1D92A',
  airQualityModerate = '#ECCB55',
  airQualityBad = '#CF5C5D',
}
