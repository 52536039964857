/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
// We want to have multiple fonts, but there is following issue:
// https://github.com/cssinjs/jss/issues/1097
import React from 'react';
import { createRoot } from 'react-dom/client';
import { createUseStyles } from 'react-jss';
import 'normalize.css';
import './borderbox.css';
import App from './App';

const useStyles = createUseStyles({
  '@global': {
    '@font-face': [
      {
        fontFamily: 'Inter',
        src: 'url("/fonts/Inter-Font.ttf") format("truetype")',
      },
      {
        fontFamily: 'Fira-Sans-Regular',
        src: 'url("/fonts/FiraSans-Regular.ttf") format("truetype")',
      },
      {
        fontFamily: 'Fira-Sans-Medium',
        src: 'url("/fonts/FiraSans-Medium.ttf") format("truetype")',
      },
      {
        fontFamily: 'Fira-Sans-Bold',
        src: 'url("/fonts/FiraSans-Bold.ttf") format("truetype")',
      },
      {
        fontFamily: 'GT-Walsheim-Bold-Oblique',
        src: 'url("/fonts/GT-Walsheim-Bold-Oblique.ttf") format("truetype")',
      },
      {
        fontFamily: 'GT-Walsheim-Bold',
        src: 'url("/fonts/GT-Walsheim-Bold.ttf") format("truetype")',
      },
    ],
    body: {
      fontFamily: '"Fira-Sans-Medium", sans-serif',
    },
  },
});

const Root: React.FC = () => {
  useStyles();
  return (
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
};

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<Root />);
