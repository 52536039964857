import React, { useEffect, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import queryString from 'query-string';
import 'chartjs-adapter-date-fns';
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from 'chart.js';
import { Layout } from './components/Layout';
import { Slides } from './components/Slides';
import {
  LocationConfiguration,
  locationConfigurations,
} from './knownLocations';
import { MeetingRoom } from './feature/meetingRoom/MeetingRoom';
import { WeatherProvider } from './api/weather-context';

const defaultConfig = Object.keys(locationConfigurations)?.[1];

ChartJS.register(
  LinearScale,
  CategoryScale,
  PointElement,
  BarElement,
  TimeScale,
  Title,
  Tooltip,
  Legend,
);

const App: React.FC = () => {
  const [locationConfig, setLocationConfig] = useState<
    LocationConfiguration | undefined
  >();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const qs = queryString.parse(window.location.search);
    const locationName = qs.location as string;
    const config = locationConfigurations[locationName || defaultConfig];
    setLocationConfig(config);
    setLoading(false);
  }, []);

  return (
    <WeatherProvider coordinates={locationConfig?.coordinates}>
      {locationConfig?.name ===
      locationConfigurations['tw50-meeting-room'].name ? (
        <MeetingRoom locationConfig={locationConfig} />
      ) : (
        <Layout locationConfig={locationConfig}>
          <Slides loading={loading} locationConfig={locationConfig} />
        </Layout>
      )}
    </WeatherProvider>
  );
};

export default App;
