export const result = {
  lat: 52.4555,
  lon: 13.4298,
  timezone: 'Europe/Berlin',
  timezone_offset: 7200,
  current: {
    dt: 1620827143,
    sunrise: 1620789308,
    sunset: 1620845414,
    temp: 15.41,
    feels_like: 15.12,
    pressure: 999,
    humidity: 81,
    dew_point: 12.17,
    uvi: 1.94,
    clouds: 75,
    visibility: 10000,
    wind_speed: 0.89,
    wind_deg: 293,
    wind_gust: 3.13,
    weather: [
      {
        id: 803,
        main: 'Clouds',
        description: 'Überwiegend bewölkt',
        icon: '04d',
      },
    ],
  },
  hourly: [
    {
      dt: 1644526800,
      temp: 5.22,
      feels_like: 2.17,
      pressure: 1012,
      humidity: 87,
      dew_point: 3.24,
      uvi: 0,
      clouds: 20,
      visibility: 10000,
      wind_speed: 3.93,
      wind_deg: 267,
      wind_gust: 9.05,
      weather: [
        {
          id: 801,
          main: 'Clouds',
          description: 'Ein paar Wolken',
          icon: '02n',
        },
      ],
      pop: 0.17,
    },
    {
      dt: 1644530400,
      temp: 5.2,
      feels_like: 2.47,
      pressure: 1010,
      humidity: 88,
      dew_point: 3.38,
      uvi: 0,
      clouds: 0,
      visibility: 10000,
      wind_speed: 3.4,
      wind_deg: 267,
      wind_gust: 7.95,
      weather: [
        {
          id: 800,
          main: 'Clear',
          description: 'Klarer Himmel',
          icon: '01n',
        },
      ],
      pop: 0.11,
    },
    {
      dt: 1644534000,
      temp: 5.22,
      feels_like: 2.58,
      pressure: 1012,
      humidity: 87,
      dew_point: 3.24,
      uvi: 0,
      clouds: 20,
      visibility: 10000,
      wind_speed: 3.27,
      wind_deg: 269,
      wind_gust: 7,
      weather: [
        {
          id: 500,
          main: 'Rain',
          description: 'Leichter Regen',
          icon: '10n',
        },
      ],
      pop: 0.3,
      rain: {
        '1h': 0.18,
      },
    },
    {
      dt: 1644537600,
      temp: 5.17,
      feels_like: 2.84,
      pressure: 1013,
      humidity: 86,
      dew_point: 3.02,
      uvi: 0,
      clouds: 40,
      visibility: 10000,
      wind_speed: 2.82,
      wind_deg: 270,
      wind_gust: 6.24,
      weather: [
        {
          id: 802,
          main: 'Clouds',
          description: 'Mäßig bewölkt',
          icon: '03n',
        },
      ],
      pop: 0.05,
    },
    {
      dt: 1644541200,
      temp: 5.01,
      feels_like: 2.76,
      pressure: 1015,
      humidity: 86,
      dew_point: 2.87,
      uvi: 0,
      clouds: 60,
      visibility: 10000,
      wind_speed: 2.69,
      wind_deg: 271,
      wind_gust: 6.12,
      weather: [
        {
          id: 803,
          main: 'Clouds',
          description: 'Überwiegend bewölkt',
          icon: '04n',
        },
      ],
      pop: 0.29,
    },
    {
      dt: 1644544800,
      temp: 4.79,
      feels_like: 2.53,
      pressure: 1016,
      humidity: 85,
      dew_point: 2.49,
      uvi: 0,
      clouds: 80,
      visibility: 10000,
      wind_speed: 2.65,
      wind_deg: 268,
      wind_gust: 5.85,
      weather: [
        {
          id: 803,
          main: 'Clouds',
          description: 'Überwiegend bewölkt',
          icon: '04n',
        },
      ],
      pop: 0.29,
    },
    {
      dt: 1644548400,
      temp: 4.49,
      feels_like: 1.82,
      pressure: 1018,
      humidity: 82,
      dew_point: 1.7,
      uvi: 0,
      clouds: 100,
      visibility: 10000,
      wind_speed: 3.1,
      wind_deg: 275,
      wind_gust: 6.47,
      weather: [
        {
          id: 804,
          main: 'Clouds',
          description: 'Bedeckt',
          icon: '04n',
        },
      ],
      pop: 0.25,
    },
    {
      dt: 1644552000,
      temp: 4.28,
      feels_like: 1.61,
      pressure: 1018,
      humidity: 80,
      dew_point: 1.17,
      uvi: 0,
      clouds: 100,
      visibility: 10000,
      wind_speed: 3.04,
      wind_deg: 278,
      wind_gust: 6.95,
      weather: [
        {
          id: 804,
          main: 'Clouds',
          description: 'Bedeckt',
          icon: '04n',
        },
      ],
      pop: 0.25,
    },
    {
      dt: 1644555600,
      temp: 4.01,
      feels_like: 1.51,
      pressure: 1018,
      humidity: 81,
      dew_point: 1.02,
      uvi: 0,
      clouds: 100,
      visibility: 10000,
      wind_speed: 2.75,
      wind_deg: 275,
      wind_gust: 6.98,
      weather: [
        {
          id: 804,
          main: 'Clouds',
          description: 'Bedeckt',
          icon: '04n',
        },
      ],
      pop: 0.25,
    },
    {
      dt: 1644559200,
      temp: 3.92,
      feels_like: 1.21,
      pressure: 1018,
      humidity: 80,
      dew_point: 0.73,
      uvi: 0,
      clouds: 100,
      visibility: 10000,
      wind_speed: 3,
      wind_deg: 271,
      wind_gust: 7.4,
      weather: [
        {
          id: 500,
          main: 'Rain',
          description: 'Leichter Regen',
          icon: '10n',
        },
      ],
      pop: 0.25,
      rain: {
        '1h': 0.11,
      },
    },
    {
      dt: 1644562800,
      temp: 3.9,
      feels_like: 0.66,
      pressure: 1019,
      humidity: 77,
      dew_point: 0.3,
      uvi: 0,
      clouds: 100,
      visibility: 10000,
      wind_speed: 3.76,
      wind_deg: 270,
      wind_gust: 7.83,
      weather: [
        {
          id: 500,
          main: 'Rain',
          description: 'Leichter Regen',
          icon: '10d',
        },
      ],
      pop: 0.55,
      rain: {
        '1h': 0.22,
      },
    },
    {
      dt: 1644566400,
      temp: 3.72,
      feels_like: -0.11,
      pressure: 1020,
      humidity: 79,
      dew_point: 0.35,
      uvi: 0.28,
      clouds: 100,
      visibility: 10000,
      wind_speed: 4.71,
      wind_deg: 278,
      wind_gust: 8.63,
      weather: [
        {
          id: 500,
          main: 'Rain',
          description: 'Leichter Regen',
          icon: '10d',
        },
      ],
      pop: 0.53,
      rain: {
        '1h': 0.3,
      },
    },
    {
      dt: 1644570000,
      temp: 4.09,
      feels_like: 0.19,
      pressure: 1020,
      humidity: 77,
      dew_point: 0.46,
      uvi: 0.56,
      clouds: 84,
      visibility: 10000,
      wind_speed: 5.02,
      wind_deg: 278,
      wind_gust: 8.56,
      weather: [
        {
          id: 803,
          main: 'Clouds',
          description: 'Überwiegend bewölkt',
          icon: '04d',
        },
      ],
      pop: 0.62,
    },
    {
      dt: 1644573600,
      temp: 5.09,
      feels_like: 1.37,
      pressure: 1021,
      humidity: 70,
      dew_point: 0.08,
      uvi: 0.84,
      clouds: 66,
      visibility: 10000,
      wind_speed: 5.17,
      wind_deg: 279,
      wind_gust: 7.88,
      weather: [
        {
          id: 803,
          main: 'Clouds',
          description: 'Überwiegend bewölkt',
          icon: '04d',
        },
      ],
      pop: 0.61,
    },
    {
      dt: 1644577200,
      temp: 5.9,
      feels_like: 2.35,
      pressure: 1022,
      humidity: 64,
      dew_point: -0.45,
      uvi: 0.97,
      clouds: 55,
      visibility: 10000,
      wind_speed: 5.26,
      wind_deg: 277,
      wind_gust: 7.18,
      weather: [
        {
          id: 803,
          main: 'Clouds',
          description: 'Überwiegend bewölkt',
          icon: '04d',
        },
      ],
      pop: 0.53,
    },
    {
      dt: 1644580800,
      temp: 6.48,
      feels_like: 3.13,
      pressure: 1022,
      humidity: 59,
      dew_point: -1.05,
      uvi: 0.9,
      clouds: 49,
      visibility: 10000,
      wind_speed: 5.13,
      wind_deg: 276,
      wind_gust: 6.89,
      weather: [
        {
          id: 802,
          main: 'Clouds',
          description: 'Mäßig bewölkt',
          icon: '03d',
        },
      ],
      pop: 0.32,
    },
    {
      dt: 1644584400,
      temp: 6.72,
      feels_like: 3.52,
      pressure: 1022,
      humidity: 57,
      dew_point: -1.18,
      uvi: 0.41,
      clouds: 70,
      visibility: 10000,
      wind_speed: 4.92,
      wind_deg: 276,
      wind_gust: 6.39,
      weather: [
        {
          id: 803,
          main: 'Clouds',
          description: 'Überwiegend bewölkt',
          icon: '04d',
        },
      ],
      pop: 0,
    },
    {
      dt: 1644588000,
      temp: 6.31,
      feels_like: 3.01,
      pressure: 1023,
      humidity: 61,
      dew_point: -0.69,
      uvi: 0.22,
      clouds: 77,
      visibility: 10000,
      wind_speed: 4.93,
      wind_deg: 281,
      wind_gust: 6.07,
      weather: [
        {
          id: 803,
          main: 'Clouds',
          description: 'Überwiegend bewölkt',
          icon: '04d',
        },
      ],
      pop: 0,
    },
    {
      dt: 1644591600,
      temp: 5.39,
      feels_like: 2.08,
      pressure: 1024,
      humidity: 65,
      dew_point: -0.53,
      uvi: 0.08,
      clouds: 78,
      visibility: 10000,
      wind_speed: 4.48,
      wind_deg: 288,
      wind_gust: 6.29,
      weather: [
        {
          id: 803,
          main: 'Clouds',
          description: 'Überwiegend bewölkt',
          icon: '04d',
        },
      ],
      pop: 0,
    },
    {
      dt: 1644595200,
      temp: 4.98,
      feels_like: 1.68,
      pressure: 1025,
      humidity: 68,
      dew_point: -0.45,
      uvi: 0,
      clouds: 84,
      visibility: 10000,
      wind_speed: 4.28,
      wind_deg: 295,
      wind_gust: 7.03,
      weather: [
        {
          id: 803,
          main: 'Clouds',
          description: 'Überwiegend bewölkt',
          icon: '04d',
        },
      ],
      pop: 0,
    },
    {
      dt: 1644598800,
      temp: 4.74,
      feels_like: 0.98,
      pressure: 1027,
      humidity: 67,
      dew_point: -0.97,
      uvi: 0,
      clouds: 87,
      visibility: 10000,
      wind_speed: 5.06,
      wind_deg: 305,
      wind_gust: 8.01,
      weather: [
        {
          id: 804,
          main: 'Clouds',
          description: 'Bedeckt',
          icon: '04n',
        },
      ],
      pop: 0,
    },
    {
      dt: 1644602400,
      temp: 3.75,
      feels_like: -0.13,
      pressure: 1028,
      humidity: 82,
      dew_point: 0.95,
      uvi: 0,
      clouds: 89,
      visibility: 10000,
      wind_speed: 4.81,
      wind_deg: 307,
      wind_gust: 8.56,
      weather: [
        {
          id: 804,
          main: 'Clouds',
          description: 'Bedeckt',
          icon: '04n',
        },
      ],
      pop: 0,
    },
    {
      dt: 1644606000,
      temp: 3.63,
      feels_like: -0.26,
      pressure: 1029,
      humidity: 74,
      dew_point: -0.58,
      uvi: 0,
      clouds: 100,
      visibility: 10000,
      wind_speed: 4.78,
      wind_deg: 309,
      wind_gust: 8.74,
      weather: [
        {
          id: 804,
          main: 'Clouds',
          description: 'Bedeckt',
          icon: '04n',
        },
      ],
      pop: 0,
    },
    {
      dt: 1644609600,
      temp: 2.8,
      feels_like: -1.33,
      pressure: 1030,
      humidity: 68,
      dew_point: -2.6,
      uvi: 0,
      clouds: 96,
      visibility: 10000,
      wind_speed: 4.84,
      wind_deg: 308,
      wind_gust: 8.95,
      weather: [
        {
          id: 804,
          main: 'Clouds',
          description: 'Bedeckt',
          icon: '04n',
        },
      ],
      pop: 0,
    },
    {
      dt: 1644613200,
      temp: 1.83,
      feels_like: -2.32,
      pressure: 1031,
      humidity: 69,
      dew_point: -3.23,
      uvi: 0,
      clouds: 69,
      visibility: 10000,
      wind_speed: 4.45,
      wind_deg: 305,
      wind_gust: 9.79,
      weather: [
        {
          id: 803,
          main: 'Clouds',
          description: 'Überwiegend bewölkt',
          icon: '04n',
        },
      ],
      pop: 0,
    },
    {
      dt: 1644616800,
      temp: 1.25,
      feels_like: -2.61,
      pressure: 1032,
      humidity: 71,
      dew_point: -3.49,
      uvi: 0,
      clouds: 52,
      visibility: 10000,
      wind_speed: 3.8,
      wind_deg: 301,
      wind_gust: 9.16,
      weather: [
        {
          id: 803,
          main: 'Clouds',
          description: 'Überwiegend bewölkt',
          icon: '04n',
        },
      ],
      pop: 0,
    },
    {
      dt: 1644620400,
      temp: 0.69,
      feels_like: -2.86,
      pressure: 1032,
      humidity: 74,
      dew_point: -3.51,
      uvi: 0,
      clouds: 42,
      visibility: 10000,
      wind_speed: 3.22,
      wind_deg: 294,
      wind_gust: 7.67,
      weather: [
        {
          id: 802,
          main: 'Clouds',
          description: 'Mäßig bewölkt',
          icon: '03n',
        },
      ],
      pop: 0,
    },
    {
      dt: 1644624000,
      temp: 0.19,
      feels_like: -3.05,
      pressure: 1033,
      humidity: 76,
      dew_point: -3.48,
      uvi: 0,
      clouds: 35,
      visibility: 10000,
      wind_speed: 2.76,
      wind_deg: 281,
      wind_gust: 6.02,
      weather: [
        {
          id: 802,
          main: 'Clouds',
          description: 'Mäßig bewölkt',
          icon: '03n',
        },
      ],
      pop: 0,
    },
    {
      dt: 1644627600,
      temp: -0.11,
      feels_like: -3.05,
      pressure: 1033,
      humidity: 78,
      dew_point: -3.49,
      uvi: 0,
      clouds: 4,
      visibility: 10000,
      wind_speed: 2.4,
      wind_deg: 276,
      wind_gust: 4.79,
      weather: [
        {
          id: 800,
          main: 'Clear',
          description: 'Klarer Himmel',
          icon: '01n',
        },
      ],
      pop: 0,
    },
    {
      dt: 1644631200,
      temp: -0.32,
      feels_like: -3.24,
      pressure: 1034,
      humidity: 79,
      dew_point: -3.55,
      uvi: 0,
      clouds: 5,
      visibility: 10000,
      wind_speed: 2.35,
      wind_deg: 274,
      wind_gust: 4.64,
      weather: [
        {
          id: 800,
          main: 'Clear',
          description: 'Klarer Himmel',
          icon: '01n',
        },
      ],
      pop: 0,
    },
    {
      dt: 1644634800,
      temp: -0.49,
      feels_like: -3.28,
      pressure: 1033,
      humidity: 79,
      dew_point: -3.68,
      uvi: 0,
      clouds: 5,
      visibility: 10000,
      wind_speed: 2.21,
      wind_deg: 272,
      wind_gust: 4.4,
      weather: [
        {
          id: 800,
          main: 'Clear',
          description: 'Klarer Himmel',
          icon: '01n',
        },
      ],
      pop: 0,
    },
    {
      dt: 1644638400,
      temp: -0.62,
      feels_like: -3.33,
      pressure: 1033,
      humidity: 79,
      dew_point: -3.79,
      uvi: 0,
      clouds: 5,
      visibility: 10000,
      wind_speed: 2.12,
      wind_deg: 261,
      wind_gust: 4.17,
      weather: [
        {
          id: 800,
          main: 'Clear',
          description: 'Klarer Himmel',
          icon: '01n',
        },
      ],
      pop: 0,
    },
    {
      dt: 1644642000,
      temp: -0.71,
      feels_like: -3.33,
      pressure: 1033,
      humidity: 80,
      dew_point: -3.85,
      uvi: 0,
      clouds: 5,
      visibility: 10000,
      wind_speed: 2.04,
      wind_deg: 245,
      wind_gust: 4,
      weather: [
        {
          id: 800,
          main: 'Clear',
          description: 'Klarer Himmel',
          icon: '01n',
        },
      ],
      pop: 0,
    },
    {
      dt: 1644645600,
      temp: -0.78,
      feels_like: -3.33,
      pressure: 1033,
      humidity: 80,
      dew_point: -3.93,
      uvi: 0,
      clouds: 5,
      visibility: 10000,
      wind_speed: 1.97,
      wind_deg: 242,
      wind_gust: 4.06,
      weather: [
        {
          id: 800,
          main: 'Clear',
          description: 'Klarer Himmel',
          icon: '01n',
        },
      ],
      pop: 0,
    },
    {
      dt: 1644649200,
      temp: -0.65,
      feels_like: -2.9,
      pressure: 1034,
      humidity: 79,
      dew_point: -3.9,
      uvi: 0,
      clouds: 5,
      visibility: 10000,
      wind_speed: 1.77,
      wind_deg: 242,
      wind_gust: 3.77,
      weather: [
        {
          id: 800,
          main: 'Clear',
          description: 'Klarer Himmel',
          icon: '01d',
        },
      ],
      pop: 0,
    },
    {
      dt: 1644652800,
      temp: 0.37,
      feels_like: -2.32,
      pressure: 1034,
      humidity: 73,
      dew_point: -3.96,
      uvi: 0.33,
      clouds: 5,
      visibility: 10000,
      wind_speed: 2.25,
      wind_deg: 219,
      wind_gust: 3.72,
      weather: [
        {
          id: 800,
          main: 'Clear',
          description: 'Klarer Himmel',
          icon: '01d',
        },
      ],
      pop: 0,
    },
    {
      dt: 1644656400,
      temp: 1.57,
      feels_like: -1.46,
      pressure: 1034,
      humidity: 66,
      dew_point: -4.18,
      uvi: 0.65,
      clouds: 7,
      visibility: 10000,
      wind_speed: 2.82,
      wind_deg: 211,
      wind_gust: 4.29,
      weather: [
        {
          id: 800,
          main: 'Clear',
          description: 'Klarer Himmel',
          icon: '01d',
        },
      ],
      pop: 0,
    },
    {
      dt: 1644660000,
      temp: 2.72,
      feels_like: -0.42,
      pressure: 1033,
      humidity: 59,
      dew_point: -4.64,
      uvi: 0.94,
      clouds: 9,
      visibility: 10000,
      wind_speed: 3.24,
      wind_deg: 208,
      wind_gust: 4.63,
      weather: [
        {
          id: 800,
          main: 'Clear',
          description: 'Klarer Himmel',
          icon: '01d',
        },
      ],
      pop: 0,
    },
    {
      dt: 1644663600,
      temp: 3.65,
      feels_like: 0.56,
      pressure: 1033,
      humidity: 52,
      dew_point: -5.28,
      uvi: 1.08,
      clouds: 15,
      visibility: 10000,
      wind_speed: 3.44,
      wind_deg: 206,
      wind_gust: 4.65,
      weather: [
        {
          id: 801,
          main: 'Clouds',
          description: 'Ein paar Wolken',
          icon: '02d',
        },
      ],
      pop: 0,
    },
    {
      dt: 1644667200,
      temp: 4.32,
      feels_like: 1.16,
      pressure: 1032,
      humidity: 48,
      dew_point: -5.69,
      uvi: 1,
      clouds: 22,
      visibility: 10000,
      wind_speed: 3.78,
      wind_deg: 201,
      wind_gust: 5.04,
      weather: [
        {
          id: 801,
          main: 'Clouds',
          description: 'Ein paar Wolken',
          icon: '02d',
        },
      ],
      pop: 0,
    },
    {
      dt: 1644670800,
      temp: 4.64,
      feels_like: 1.41,
      pressure: 1030,
      humidity: 46,
      dew_point: -5.96,
      uvi: 0.71,
      clouds: 97,
      visibility: 10000,
      wind_speed: 4.02,
      wind_deg: 198,
      wind_gust: 5.43,
      weather: [
        {
          id: 804,
          main: 'Clouds',
          description: 'Bedeckt',
          icon: '04d',
        },
      ],
      pop: 0,
    },
    {
      dt: 1644674400,
      temp: 4.67,
      feels_like: 1.46,
      pressure: 1030,
      humidity: 46,
      dew_point: -6.08,
      uvi: 0.39,
      clouds: 95,
      visibility: 10000,
      wind_speed: 3.99,
      wind_deg: 195,
      wind_gust: 5.68,
      weather: [
        {
          id: 804,
          main: 'Clouds',
          description: 'Bedeckt',
          icon: '04d',
        },
      ],
      pop: 0,
    },
    {
      dt: 1644678000,
      temp: 4.22,
      feels_like: 1.17,
      pressure: 1029,
      humidity: 49,
      dew_point: -5.61,
      uvi: 0.14,
      clouds: 94,
      visibility: 10000,
      wind_speed: 3.57,
      wind_deg: 187,
      wind_gust: 6.3,
      weather: [
        {
          id: 804,
          main: 'Clouds',
          description: 'Bedeckt',
          icon: '04d',
        },
      ],
      pop: 0,
    },
    {
      dt: 1644681600,
      temp: 3.25,
      feels_like: 0.25,
      pressure: 1028,
      humidity: 54,
      dew_point: -5.19,
      uvi: 0,
      clouds: 96,
      visibility: 10000,
      wind_speed: 3.2,
      wind_deg: 180,
      wind_gust: 7,
      weather: [
        {
          id: 804,
          main: 'Clouds',
          description: 'Bedeckt',
          icon: '04d',
        },
      ],
      pop: 0,
    },
    {
      dt: 1644685200,
      temp: 2.54,
      feels_like: -0.56,
      pressure: 1028,
      humidity: 59,
      dew_point: -4.78,
      uvi: 0,
      clouds: 97,
      visibility: 10000,
      wind_speed: 3.14,
      wind_deg: 175,
      wind_gust: 7.61,
      weather: [
        {
          id: 804,
          main: 'Clouds',
          description: 'Bedeckt',
          icon: '04n',
        },
      ],
      pop: 0,
    },
    {
      dt: 1644688800,
      temp: 1.99,
      feels_like: -1.25,
      pressure: 1027,
      humidity: 64,
      dew_point: -4.27,
      uvi: 0,
      clouds: 97,
      visibility: 10000,
      wind_speed: 3.17,
      wind_deg: 173,
      wind_gust: 8.31,
      weather: [
        {
          id: 804,
          main: 'Clouds',
          description: 'Bedeckt',
          icon: '04n',
        },
      ],
      pop: 0,
    },
    {
      dt: 1644692400,
      temp: 1.7,
      feels_like: -1.56,
      pressure: 1027,
      humidity: 69,
      dew_point: -3.46,
      uvi: 0,
      clouds: 100,
      visibility: 10000,
      wind_speed: 3.13,
      wind_deg: 174,
      wind_gust: 8.82,
      weather: [
        {
          id: 804,
          main: 'Clouds',
          description: 'Bedeckt',
          icon: '04n',
        },
      ],
      pop: 0,
    },
    {
      dt: 1644696000,
      temp: 1.55,
      feels_like: -1.86,
      pressure: 1026,
      humidity: 73,
      dew_point: -2.74,
      uvi: 0,
      clouds: 100,
      visibility: 10000,
      wind_speed: 3.28,
      wind_deg: 174,
      wind_gust: 9.9,
      weather: [
        {
          id: 804,
          main: 'Clouds',
          description: 'Bedeckt',
          icon: '04n',
        },
      ],
      pop: 0,
    },
  ],
  daily: [
    {
      dt: 1620817200,
      sunrise: 1620789308,
      sunset: 1620845414,
      moonrise: 1620790920,
      moonset: 1620848580,
      moon_phase: 0.02,
      temp: {
        day: 15.25,
        min: 12.49,
        max: 15.77,
        night: 13.8,
        eve: 15.57,
        morn: 12.52,
      },
      feels_like: {
        day: 14.95,
        night: 13.48,
        eve: 15.19,
        morn: 12.31,
      },
      pressure: 1004,
      humidity: 81,
      dew_point: 12.01,
      wind_speed: 4.63,
      wind_deg: 284,
      wind_gust: 9.17,
      weather: [
        {
          id: 502,
          main: 'Rain',
          description: 'Starker Regen',
          icon: '10d',
        },
      ],
      clouds: 90,
      pop: 1,
      rain: 12.88,
      uvi: 2.63,
    },
    {
      dt: 1620903600,
      sunrise: 1620875611,
      sunset: 1620931912,
      moonrise: 1620878640,
      moonset: 1620939060,
      moon_phase: 0.05,
      temp: {
        day: 10.55,
        min: 8.88,
        max: 13.51,
        night: 8.88,
        eve: 9.67,
        morn: 11.32,
      },
      feels_like: {
        day: 10.19,
        night: 6.2,
        eve: 7.14,
        morn: 11.04,
      },
      pressure: 1002,
      humidity: 97,
      dew_point: 10.06,
      wind_speed: 5.42,
      wind_deg: 282,
      wind_gust: 10.6,
      weather: [
        {
          id: 501,
          main: 'Rain',
          description: 'Mäßiger Regen',
          icon: '10d',
        },
      ],
      clouds: 100,
      pop: 1,
      rain: 25.07,
      uvi: 2.61,
    },
    {
      dt: 1620990000,
      sunrise: 1620961916,
      sunset: 1621018408,
      moonrise: 1620966780,
      moonset: 1621029300,
      moon_phase: 0.08,
      temp: {
        day: 8.76,
        min: 8.09,
        max: 12.71,
        night: 8.41,
        eve: 12.18,
        morn: 8.24,
      },
      feels_like: {
        day: 6.13,
        night: 7.74,
        eve: 11.33,
        morn: 5.44,
      },
      pressure: 1006,
      humidity: 92,
      dew_point: 7.47,
      wind_speed: 5.54,
      wind_deg: 271,
      wind_gust: 10.85,
      weather: [
        {
          id: 501,
          main: 'Rain',
          description: 'Mäßiger Regen',
          icon: '10d',
        },
      ],
      clouds: 100,
      pop: 0.82,
      rain: 6.6,
      uvi: 2.48,
    },
    {
      dt: 1621076400,
      sunrise: 1621048223,
      sunset: 1621104904,
      moonrise: 1621055460,
      moonset: 0,
      moon_phase: 0.11,
      temp: {
        day: 17.95,
        min: 6.88,
        max: 17.95,
        night: 10.98,
        eve: 14.31,
        morn: 8.93,
      },
      feels_like: {
        day: 17.13,
        night: 10.48,
        eve: 13.62,
        morn: 8.93,
      },
      pressure: 1007,
      humidity: 51,
      dew_point: 7.6,
      wind_speed: 3.71,
      wind_deg: 218,
      wind_gust: 6.75,
      weather: [
        {
          id: 500,
          main: 'Rain',
          description: 'Leichter Regen',
          icon: '10d',
        },
      ],
      clouds: 48,
      pop: 0.8,
      rain: 1.92,
      uvi: 4.18,
    },
    {
      dt: 1621162800,
      sunrise: 1621134532,
      sunset: 1621191398,
      moonrise: 1621144800,
      moonset: 1621119060,
      moon_phase: 0.14,
      temp: {
        day: 15.27,
        min: 7.9,
        max: 16.02,
        night: 10.24,
        eve: 13.5,
        morn: 10.6,
      },
      feels_like: {
        day: 14.47,
        night: 9.64,
        eve: 12.79,
        morn: 9.83,
      },
      pressure: 1007,
      humidity: 62,
      dew_point: 8.03,
      wind_speed: 4.59,
      wind_deg: 200,
      wind_gust: 6.25,
      weather: [
        {
          id: 500,
          main: 'Rain',
          description: 'Leichter Regen',
          icon: '10d',
        },
      ],
      clouds: 81,
      pop: 0.88,
      rain: 1.33,
      uvi: 2.57,
    },
    {
      dt: 1621249200,
      sunrise: 1621220843,
      sunset: 1621277892,
      moonrise: 1621234860,
      moonset: 1621208280,
      moon_phase: 0.17,
      temp: {
        day: 16.56,
        min: 7.61,
        max: 16.56,
        night: 9.56,
        eve: 11.94,
        morn: 10.78,
      },
      feels_like: {
        day: 15.73,
        night: 8.42,
        eve: 11.49,
        morn: 9.9,
      },
      pressure: 1006,
      humidity: 56,
      dew_point: 7.7,
      wind_speed: 4.46,
      wind_deg: 248,
      wind_gust: 7.3,
      weather: [
        {
          id: 501,
          main: 'Rain',
          description: 'Mäßiger Regen',
          icon: '10d',
        },
      ],
      clouds: 23,
      pop: 1,
      rain: 5.97,
      uvi: 3,
    },
    {
      dt: 1621335600,
      sunrise: 1621307156,
      sunset: 1621364384,
      moonrise: 1621325400,
      moonset: 1621296780,
      moon_phase: 0.2,
      temp: {
        day: 15.13,
        min: 9.27,
        max: 15.13,
        night: 9.68,
        eve: 12.91,
        morn: 10.54,
      },
      feels_like: {
        day: 14.34,
        night: 8.31,
        eve: 12.29,
        morn: 9.92,
      },
      pressure: 1011,
      humidity: 63,
      dew_point: 8.12,
      wind_speed: 4.83,
      wind_deg: 283,
      wind_gust: 7.7,
      weather: [
        {
          id: 500,
          main: 'Rain',
          description: 'Leichter Regen',
          icon: '10d',
        },
      ],
      clouds: 62,
      pop: 1,
      rain: 5.05,
      uvi: 3,
    },
    {
      dt: 1621422000,
      sunrise: 1621393471,
      sunset: 1621450875,
      moonrise: 1621416420,
      moonset: 1621384860,
      moon_phase: 0.25,
      temp: {
        day: 17.62,
        min: 7.95,
        max: 17.62,
        night: 10.15,
        eve: 13.58,
        morn: 10.9,
      },
      feels_like: {
        day: 16.72,
        night: 9.68,
        eve: 12.98,
        morn: 10.24,
      },
      pressure: 1013,
      humidity: 49,
      dew_point: 6.77,
      wind_speed: 5.75,
      wind_deg: 228,
      wind_gust: 8.6,
      weather: [
        {
          id: 500,
          main: 'Rain',
          description: 'Leichter Regen',
          icon: '10d',
        },
      ],
      clouds: 70,
      pop: 0.96,
      rain: 2.21,
      uvi: 3,
    },
  ],
};
