import React from 'react';
import { FaTemperatureHigh } from 'react-icons/fa';
import { ValueCard } from './ValueCard';
import { useSensorData } from '../../api';

type TemperatureCardProps = {
  temperatureId: string;
};
export const TemperatureCard: React.FC<TemperatureCardProps> = ({
  temperatureId,
}) => {
  const { data } = useSensorData(temperatureId);
  return (
    <ValueCard
      value={data.toFixed(0).toString()}
      unit="o"
      icon={FaTemperatureHigh}
    />
  );
};
