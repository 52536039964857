import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { createUseStyles } from 'react-jss';
import {
  LocationConfiguration,
  locationConfigurations,
} from '../../knownLocations';
import { Carousel, Slide } from '../Carousel';
import { ImagesSlide } from '../../feature/images/ImagesSlide';
import { WeatherSlide } from '../../feature/weather/WeatherSlide';
import { AirQualitySlide } from '../../feature/airQuality';
import { useWeather } from '../../api/weather-context';

const useStyles = createUseStyles({
  statusMessage: {
    fontSize: '3rem',
    color: 'white',
  },
  errorMessage: {
    fontSize: '3rem',
    color: 'red',
  },
});

type SlidesProps = {
  loading: boolean;
  locationConfig: LocationConfiguration | undefined;
};

const slideDurations = {
  images: 16000,
  co2: 8000,
  weather: 12000,
};

export const Slides: React.FC<SlidesProps> = ({ loading, locationConfig }) => {
  const classes = useStyles();
  const { data: weather } = useWeather();

  // we can't use dynamic slides within the carousel to achieve this, because the carousel
  // is not able to handle changes to the number of slides so we change the whole content of
  // the left side
  const slides = [];
  const key = [];

  // show Initializing ... on first render, before useEffect kicks in
  if (loading) {
    key.push('initializing');
    slides.push(
      <Slide key="initializing">
        <div className={classes.statusMessage}>Initializing ...</div>
      </Slide>,
    );
  }

  // show instructions if no configuration is found
  if (!loading && !locationConfig) {
    key.push('instructions');
    slides.push(
      <Slide key="instructions">
        <div className={classes.errorMessage}>
          <p>
            Please specify a location parameter in the url (e.g.
            ?location=tw50).
          </p>
          <p>
            Known locations:
            <br />
            {Object.keys(locationConfigurations).map((c) => (
              <>
                <a href={`/?location=${c}`}>{locationConfigurations[c].name}</a>
                <br />
              </>
            ))}
          </p>
        </div>
      </Slide>,
    );
  }

  // show carousel if locationConfig is loaded
  if (!loading && locationConfig) {
    if (locationConfig?.co2 && locationConfig?.peopleCounter) {
      key.push('co2');
      slides.push(
        <Slide duration={slideDurations.co2} key="co2">
          <AirQualitySlide
            co2Id={locationConfig.co2}
            peopleCounterId={locationConfig.peopleCounter}
          />
        </Slide>,
      );
    }
    if (weather && locationConfig?.city) {
      key.push('weather');
      slides.push(
        <Slide duration={slideDurations.weather} key="weather">
          <WeatherSlide />
        </Slide>,
      );
    }
    if (locationConfig?.imageUrl) {
      key.push('images');
      slides.push(
        <Slide duration={slideDurations.images} key="images">
          <ImagesSlide imageFolder={locationConfig.imageUrl} />
        </Slide>,
      );
    }
  }
  return <Carousel key={key.toString()}>{slides}</Carousel>;
};
