import React from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { Color } from '../../constants';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    padding: '1rem',
    borderRadius: '1rem',
  },
  title: {
    color: Color.black,
    margin: 0,
    padding: 0,
    fontSize: 'clamp(1.5rem, 2vw, 16rem)',
    textAlign: 'left',
  },
});

interface CardProps {
  className?: string;
  titleClassName?: string;
  title?: string;
}

// eslint-disable-next-line react/display-name
export const Card = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<CardProps>
>(({ title, className, titleClassName, children }, ref) => {
  const classes = useStyles();
  return (
    <div className={classNames(classes.root, className)} ref={ref}>
      {title && (
        <p className={classes.title}>
          <span className={titleClassName}>{title}</span>
        </p>
      )}
      {children}
    </div>
  );
});
