import React from 'react';
import { createUseStyles } from 'react-jss';
import { Card } from '../../components/Card';

const useStyles = createUseStyles({
  row: {
    margin: '2rem 0 0 0',
    padding: 0,
    fontSize: '4rem',
  },
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface MeetingsCardProps {}

export const MeetingsCard: React.FC<MeetingsCardProps> = () => {
  const classes = useStyles();
  return (
    <Card title="Nächste Buchung">
      <p className={classes.row}>15:00 - 21:00</p>
      <p className={classes.row}>Regina Del Prete</p>
    </Card>
  );
};
