import React from 'react';
import { MdWaterDrop } from 'react-icons/md';
import { ValueCard } from './ValueCard';
import { useSensorData } from '../../api';

type HumidityCardProps = {
  humidityId: string;
};
export const HumidityCard: React.FC<HumidityCardProps> = ({ humidityId }) => {
  const { data } = useSensorData(humidityId);
  return (
    <ValueCard value={data.toFixed(0).toString()} unit="%" icon={MdWaterDrop} />
  );
};
