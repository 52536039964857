import { result as fakeResult } from './fakeResult';

export type WeatherDataCurrent = {
  dt: number;
  sunrise: number;
  sunset: number;
  feels_like: number;
  pressure: number;
  dew_point: number;
  uvi: number;
  clouds: number;
  visibility: number;
  wind_speed: number;
  wind_deg: number;
  weather: Array<{
    id: number;
    main: string;
    description: string;
    icon: string;
  }>;
  rain?: {
    '1h': number;
  };
  snow?: {
    '1h': number;
  };
};

export type WeatherDataMain = {
  temp: number;
  humidity: number;
};
export type WeatherDataHourly = {
  dt: number;
  temp: number;
  feels_like: number;
  pressure: number;
  humidity: number;
  dew_point: number;
  uvi: number;
  clouds: number;
  visibility: number;
  wind_speed: number;
  wind_deg: number;
  wind_gust: number;
  weather: Array<{
    id: number;
    main: string;
    description: string;
    icon: string;
  }>;
  pop: number;
  rain?: {
    '1h': number;
  };
  snow?: {
    '1h': number;
  };
};
export type WeatherDataDaily = {
  dt: number;
  sunrise: number;
  sunset: number;
  moonrise: number;
  moonset: number;
  moon_phase: number;
  temp: {
    day: number;
    min: number;
    max: number;
    night: number;
    eve: number;
    morn: number;
  };
  feels_like: {
    day: number;
    night: number;
    eve: number;
    morn: number;
  };
  pressure: number;
  humidity: number;
  dew_point: number;
  wind_speed: number;
  wind_deg: number;
  wind_gust: number;
  weather: Array<{
    id: number;
    main: string;
    description: string;
    icon: string;
  }>;
  clouds: number;
  pop: number;
  rain?: number;
  uvi: number;
};
export type WeatherData = {
  lat: number;
  lon: number;
  timezone: string;
  timezone_offset: number;
  main?: WeatherDataMain;
  current?: WeatherDataCurrent;
  weather?: Array<{
    id: number;
    main: string;
    description: string;
    icon: string;
  }>;
  minutely?: Array<{
    dt: number;
    precipitation: number;
  }>;
  hourly?: Array<WeatherDataHourly>;
  daily?: Array<WeatherDataDaily>;
  alerts?: Array<{
    sender_name: string;
    event: string;
    start: number;
    end: number;
    description: string;
  }>;
  sys?: {
    sunrise: number;
    sunset: number;
  };
};

const { REACT_APP_USEFAKEWEATHERDATA, NODE_ENV } = process.env;
const useFakeData = REACT_APP_USEFAKEWEATHERDATA === 'true';

export const getWeatherData = async (coords: {
  lat: number;
  lon: number;
}): Promise<WeatherData> => {
  const baseUrl = 'https://api.openweathermap.org/data/2.5/weather';
  const coordinates = `lat=${coords.lat}&lon=${coords.lon}`;
  const exclude = 'exclude=hourly,minutely';
  const units = 'units=metric';
  const lang = 'lang=de';
  const appId = 'appid=26d98625fdae92796594bfa1caa278c1';
  // TO DO: API KEY would be better if placed in .env
  const url = `${baseUrl}?${coordinates}&${units}&${exclude}&${appId}&${lang}`;

  if (useFakeData) {
    console.info('Using fake Weather data to save API calls.');
    return fakeResult;
  }

  if (NODE_ENV === 'development') {
    console.warn(
      'Using real Weather data be careful with the number of API calls.',
      '\nCreate a .env file with `REACT_APP_USEFAKEWEATHERDATA=true` to use fake data.',
    );
  }
  const response = await fetch(url, {
    method: 'GET',
  });

  if (!response.ok) throw new Error(response.statusText);
  return (await response.json()) as WeatherData;
};
