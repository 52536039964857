import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { useInterval } from 'beautiful-react-hooks';
import { Color, slideHeight, slideWidth } from '../../constants';
import { API_URI } from '../../utils/env';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    height: slideHeight,
    width: slideWidth,
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
  },
  wrapper: {
    position: 'absolute',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    opacity: 0,
    transition: 'opacity 0.2s',
  },
  active: {
    opacity: 1,
  },
  title: {
    backgroundColor: Color.kpOrange,
    color: Color.white,
    opacity: 0.75,
    fontSize: 'clamp(1.5rem, 7vh, 16rem)',
  },
});

interface ImageCarouselProps {
  images: string[];
  imageFolder: string;
}

export const ImageCarousel: React.FC<ImageCarouselProps> = ({
  images,
  imageFolder,
}) => {
  const classes = useStyles();
  const [active, setActive] = useState(0);

  useInterval(() => {
    if (active === images.length - 1) {
      setActive(0);
    } else {
      setActive(active + 1);
    }
  }, 2500);

  return (
    <div className={classes.root}>
      {images.map((src, index) => (
        <div
          className={classNames(
            classes.wrapper,
            active === index && classes.active,
          )}
          key={index.toString()}
          style={{
            backgroundImage: `url('${API_URI}/file-list/${imageFolder}/${src}')`,
          }}
        >
          <span className={classes.title}>
            {src.replace('.jpg', '').split('_')[0].toUpperCase()}
          </span>
        </div>
      ))}
    </div>
  );
};
