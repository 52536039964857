import { useState, useEffect, useCallback } from 'react';
import { useInterval } from 'beautiful-react-hooks';
import {
  AggregatedData,
  getAggregatedData,
  getSensorData,
} from './capabilityData';

import {
  WeatherData,
  WeatherDataCurrent,
  WeatherDataHourly,
  WeatherDataDaily,
  getWeatherData,
} from './weather';

export type {
  WeatherData,
  WeatherDataCurrent,
  WeatherDataHourly,
  WeatherDataDaily,
};

export { getWeatherData };

// Each two minutes
const INTERVAL = 1000 * 60 * 2;

export const useSensorData = (
  id: string,
): { data: number; error: Error | null } => {
  const [data, setData] = useState(0);
  const [error, setError] = useState<Error | null>(null);

  const fetch = useCallback(() => {
    getSensorData(id)
      .then((response) => setData(response.value || 0))
      .catch((e) => {
        console.error(e);
        setError(e);
      });
  }, [id]);

  // fetch each five minutes
  useInterval(fetch, INTERVAL);
  useEffect(() => fetch(), [fetch]);

  return { data, error };
};

export const useAggregatedData = (
  id: string,
): { data: AggregatedData[]; error: Error | null } => {
  const [data, setData] = useState<AggregatedData[]>([]);
  const [error, setError] = useState<Error | null>(null);

  const fetch = useCallback(() => {
    getAggregatedData(id)
      .then((response) => setData(response || []))
      .catch((e) => {
        console.error(e);
        setError(e);
      });
  }, [id]);

  // fetch each five minutes
  useInterval(fetch, INTERVAL);
  useEffect(() => fetch(), [fetch]);

  return { data, error };
};
