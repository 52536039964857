import React from 'react';
import { createUseStyles } from 'react-jss';
import { Color, mainHeight, padding } from '../../constants';
import { LocationConfiguration } from '../../knownLocations';
import { Header } from '../Header';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    height: '100vh',
    flexDirection: 'column',
  },
  main: {
    display: 'flex',
    width: '100%',
    height: mainHeight,
    justifyContent: 'center',
    alignItems: 'center',
    background: Color.slideBackground,
    fontSize: 'clamp(0.9rem, 1.8vw, 5.3rem)',
    padding,
  },
});

interface LayoutProps {
  children?: React.ReactNode;
  locationConfig: LocationConfiguration | undefined;
}

export const Layout: React.FC<LayoutProps> = ({ children, locationConfig }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Header co2Id={locationConfig?.co2 || ''} />
      <div className={classes.main}>{children}</div>
    </div>
  );
};
