export type LocationConfiguration = {
  name: string;
  coordinates: { lat: number; lon: number };
  imageUrl: string;
  city: string;
  humidity?: string;
  temperature?: string;
  co2?: string;
  peopleCounter?: string;
};
type LocationConfigurations = { [key: string]: LocationConfiguration };

export const locationConfigurations: LocationConfigurations = {
  'tw50-meeting-room': {
    name: 'Kieback Headquarters / Meeting Room',
    coordinates: { lat: 52.45549836090675, lon: 13.429815309925168 },
    imageUrl: 'tw50',
    city: 'Berlin Neukölln',
    humidity: 'tw50_humidity',
    temperature: 'tw50_temperature',
    co2: 'tw50_co2',
    peopleCounter: 'tw50_peopleCounter',
  },
  tw50: {
    name: 'Kieback Headquarters',
    coordinates: { lat: 52.45549836090675, lon: 13.429815309925168 },
    imageUrl: 'tw50',
    city: 'Berlin Neukölln',
    humidity: 'tw50_humidity',
    temperature: 'tw50_temperature',
    co2: 'tw50_co2',
    peopleCounter: 'tw50_peopleCounter',
  },
  paris: {
    name: 'Paris KP',
    coordinates: { lat: 49.03168477119558, lon: 2.1063773687697362 },
    imageUrl: '',
    city: 'Paris',
  },
};
