import React, { useRef, useState } from 'react';
import { format } from 'date-fns';
import de from 'date-fns/esm/locale/de';
import { createUseStyles } from 'react-jss';
import { useInterval } from 'beautiful-react-hooks';
import { Card } from '../../components/Card';
import { WeatherIcon } from '../../components/WeatherIcon';
import { useWeather } from '../../api/weather-context';

const useStyles = createUseStyles({
  root: {},
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexGrow: 1,
  },
  date: {
    fontSize: '4rem',
  },
});

interface WeatherCardProps {
  title: string;
}

export const WeatherCard: React.FC<WeatherCardProps> = ({ title }) => {
  const classes = useStyles();
  const ref = useRef<HTMLDivElement>(null);
  const weather = useWeather();
  const [time, setTime] = useState(
    format(new Date(), 'HH:mm - PP', { locale: de }),
  );
  const tickClock = () =>
    setTime(format(new Date(), 'HH:mm - PP', { locale: de }));
  useInterval(tickClock, 1000);

  if (!weather?.data) return null;

  const { data } = weather;
  const { current } = data;
  const weatherIcon = current?.weather?.[0]?.icon || '';

  return (
    <Card title={title} className={classes.root} ref={ref}>
      <div className={classes.wrapper}>
        <p className={classes.date}>{time}</p>
        <WeatherIcon type={weatherIcon} size="15rem" />
      </div>
    </Card>
  );
};
