import React from 'react';
import { FaUserFriends } from 'react-icons/fa';
import { ValueCard } from './ValueCard';
import { useSensorData } from '../../api';

type PeopleCardProps = {
  peopleCounterId: string;
};
export const PeopleCard: React.FC<PeopleCardProps> = ({ peopleCounterId }) => {
  const { data } = useSensorData(peopleCounterId);

  return (
    <ValueCard
      value={data < 0 ? '-' : data.toFixed(0).toString()}
      icon={FaUserFriends}
    />
  );
};
