import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import { Bar } from 'react-chartjs-2';
import de from 'date-fns/locale/de';
import { Card } from '../../components/Card';
import { Color } from '../../constants';
import { useAggregatedData, useSensorData } from '../../api';

enum AirQuality {
  GOOD = 'GOOD',
  MODERATE = 'MODERATE',
  BAD = 'BAD',
}

const icon = {
  [AirQuality.GOOD]: '/svg/smile-face.svg',
  [AirQuality.MODERATE]: '/svg/neutral-face.svg',
  [AirQuality.BAD]: '/svg/frown-face.svg',
};

const useStyles = createUseStyles({
  root: {},
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    flexGrow: 0,
  },
  quality: {
    display: 'flex',
    width: '15rem',
  },
  image: {
    margin: '1rem',
    height: '11rem',
  },
  chart: {
    width: 'calc(100% - 35rem)',
    overflow: 'hidden',
    height: '90%',
    flexGrow: 1,
    display: 'flex',
  },
});

interface CO2CardProps {
  co2Id: string;
}

export const CO2Card: React.FC<CO2CardProps> = ({ co2Id }) => {
  const classes = useStyles();
  const { data: co2 } = useSensorData(co2Id);
  const { data: aggregatedData } = useAggregatedData(co2Id);

  const airQuality = useMemo(() => {
    const value = Math.min(2000, co2 || 0);
    if (value > 1200) {
      return AirQuality.BAD;
    }
    if (value > 1000) {
      return AirQuality.MODERATE;
    }
    return AirQuality.GOOD;
  }, [co2]);

  const data = (aggregatedData || []).map(
    ({ utcTimeMeasured, valueString }) => ({
      x: new Date(utcTimeMeasured),
      y: parseInt(valueString, 10),
    }),
  );

  return (
    <Card title="CO2 Verlauf" className={classes.root}>
      <div className={classes.container}>
        <div className={classes.quality}>
          <img src={icon[airQuality]} alt="quality" className={classes.image} />
        </div>
        <div className={classes.chart}>
          <Bar
            options={{
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                x: {
                  type: 'time',
                  time: {
                    displayFormats: {
                      minute: 'p',
                    },
                    unit: 'minute',
                    stepSize: 15,
                  },
                  adapters: {
                    date: {
                      locale: de,
                    },
                  },
                  grid: {
                    display: true,
                    drawOnChartArea: false,
                  },
                },
              },
              plugins: {
                legend: {
                  display: false,
                },
                title: {
                  display: false,
                },
              },
            }}
            data={{
              datasets: [
                {
                  label: 'CO2',
                  data,
                  backgroundColor: [
                    Color.b2bBlack,
                    Color.b2bBlack,
                    Color.b2bBlack,
                    Color.b2bBlack,
                    Color.b2bBlack,
                  ],
                },
              ],
            }}
          />
        </div>
      </div>
    </Card>
  );
};
