import React, { useEffect, useState, useCallback } from 'react';
import { useInterval } from 'beautiful-react-hooks';
import { getImageList } from './api';
import { ImageCarousel } from '../../components/ImageCarousel';

type ImagesSlideProps = {
  imageFolder: string;
};

export const ImagesSlide: React.FC<ImagesSlideProps> = ({ imageFolder }) => {
  const [images, setImages] = useState<string[]>([]);

  const getImages = useCallback(() => {
    getImageList(imageFolder)
      .then((data: string[]) => {
        console.log('Received files', { data });
        if (Array.isArray(data)) {
          setImages(data);
        } else {
          setImages([]);
        }
        return null;
      })
      .catch(console.error);
  }, [imageFolder]);

  useInterval(() => {
    getImages();
  }, 30000);

  useEffect(() => {
    getImages();
  }, [getImages]);

  return <ImageCarousel images={images} imageFolder={imageFolder} />;
};
