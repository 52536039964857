import React from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { Color } from '../../constants';
import { WelcomeCard } from './WelcomeCard';
import { WeatherCard } from './WeatherCard';
import { MeetingsCard } from './MeetingsCard';
import { CO2Card } from './CO2Card';
import { TemperatureCard } from './TemperatureCard';
import { PeopleCard } from './PeopleCard';
import { HumidityCard } from './HumidityCard';
import { Logo } from '../../components/Logo';
import { LocationConfiguration } from '../../knownLocations';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    height: '100vh',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 'clamp(0.9rem, 1.8vw, 5.3rem)',
    flexDirection: 'column',
    padding: '8px',
  },
  headRow: {
    width: '100%',
    display: 'flex',
    flexGrow: 0,
    flexBasis: '5%',
    maxHeight: '5%',
    alignItems: 'center',
    flexShrink: 0,
    justifyContent: 'space-between',
  },
  row: {
    width: '100%',
    display: 'flex',
    '& + &': {
      marginTop: '8px',
    },
    flexGrow: 0,
    flexBasis: '29%',
    maxHeight: '29%',
    flexShrink: 0,
  },
  cell: {
    background: '#E2E0DC',
    flexGrow: 1,
    flexShrink: 0,
    display: 'flex',
    '& + &': { marginLeft: '8px' },
  },
  cellSmall: {
    flexBasis: '33%',
    flexGrow: 0,
    flexShrink: 0,
  },
  logo: {
    flexBasis: '17%',
  },
  logoSvg: {
    marginTop: '0.4rem',
    marginLeft: '-3rem',
  },
  room: {
    color: Color.b2bBlack,
    marginRight: '1rem',
    padding: 0,
    fontSize: '3rem',
  },
  sloganBlue: {
    fontSize: '2rem',
    background: Color.b2bBlack,
    color: Color.white,
    padding: '0 0.5rem',
    marginLeft: '1rem',
  },
  sloganOrange: {
    fontSize: '2rem',
    background: Color.kpOrange,
    color: Color.white,
    padding: '0 0.5rem',
  },
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
type MeetingRoomProps = {
  locationConfig: LocationConfiguration | undefined;
};

export const MeetingRoom: React.FC<MeetingRoomProps> = ({ locationConfig }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.headRow}>
        <div className={classes.logo}>
          <Logo height="100%" width="auto" className={classes.logoSvg} />
        </div>
        <div className={classes.room}>Besprechungsraum 5.01</div>
      </div>
      <div className={classes.row}>
        <div className={classNames(classes.cell, classes.cellSmall)}>
          <WelcomeCard />
        </div>
        <div className={classes.cell}>
          <WeatherCard title="Tempelhofer Weg 50, Berlin" />
        </div>
      </div>
      <div className={classes.row}>
        <div className={classNames(classes.cell, classes.cellSmall)}>
          <MeetingsCard />
        </div>
        <div className={classes.cell}>
          {locationConfig?.co2 && <CO2Card co2Id={locationConfig.co2} />}
        </div>
      </div>
      <div className={classes.row}>
        <div className={classNames(classes.cell, classes.cellSmall)}>
          {locationConfig?.peopleCounter && (
            <PeopleCard peopleCounterId={locationConfig.peopleCounter} />
          )}
        </div>
        <div className={classes.cell}>
          {locationConfig?.temperature && (
            <TemperatureCard temperatureId={locationConfig.temperature} />
          )}
        </div>
        <div className={classes.cell}>
          {locationConfig?.humidity && (
            <HumidityCard humidityId={locationConfig.humidity} />
          )}
        </div>
      </div>
      <div className={classes.headRow}>
        <div>
          <span className={classes.sloganBlue}>
            From hidden champion to digital champion for
          </span>
          <span className={classes.sloganOrange}>Green Buildings</span>
        </div>
      </div>
    </div>
  );
};
