import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import { Bar } from 'react-chartjs-2';
import de from 'date-fns/locale/de';
import classNames from 'classnames';
import { FaUserFriends } from 'react-icons/fa';
import { Card } from '../../components/Card/Card';
import { Color, slideHeight, slideWidth } from '../../constants';
import { useAggregatedData, useSensorData } from '../../api';

const cardPadding = 24;

enum AirQuality {
  GOOD = 'GOOD',
  MODERATE = 'MODERATE',
  BAD = 'BAD',
}
const presentation = {
  [AirQuality.GOOD]: {
    icon: '/svg/smile-face.svg',
    text: 'Gute Luftqualität',
    recommendation: 'Weiter So',
  },

  [AirQuality.MODERATE]: {
    icon: '/svg/neutral-face.svg',
    text: 'Adäquate Luft',
    recommendation: '2 Min Lüften',
  },
  [AirQuality.BAD]: {
    icon: '/svg/frown-face.svg',
    text: 'Schlechte Luft',
    recommendation: '10 Min Lüften',
  },
};

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    height: slideHeight,
    width: slideWidth,
  },
  leftColumn: {
    display: 'flex',
    height: '100%',
    width: '40%',
    minWidth: 280,
    flexDirection: 'column',
    paddingRight: cardPadding,
    alignContent: 'space-between',
  },
  rightColumn: {
    display: 'flex',
    height: '100%',
    width: '60%',
    paddingLeft: cardPadding,
  },
  leftCard: {
    display: 'flex',
    height: '50%',
    width: '100%',
    padding: '1.5vw',
    background: Color.white,
  },
  leftMiddleCard: {
    marginTop: cardPadding * 2,
    marginBottom: cardPadding * 2,
    padding: '1.5vw',
  },
  rightCard: {
    display: 'flex',
    height: '100%',
    width: '100%',
    padding: '1.5vw',
    background: Color.white,
  },
  cardTitle: {
    fontSize: 'clamp(1.5rem, 2vw, 16rem)',
    textAlign: 'left',
    padding: 10,
  },
  container: {
    display: 'flex',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    flexDirection: 'row',
    flexGrow: 0,
  },
  quality: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'baseline',
  },
  spaceBetween: {
    alignContent: 'space-between',
    justifyContent: 'space-between',
  },
  center: {
    alignItems: 'center',
  },
  paddingBottom: {
    paddingBottom: '2vh',
  },
  value: {
    color: Color.kpOrange,
    margin: '1rem',
    padding: 0,
    fontSize: 'clamp(1.5rem, 3vw, 16rem)',
  },
  icon: {
    margin: '1rem',
    height: 'clamp(1.5rem, 3vw, 16rem)',
    width: 'clamp(1.5rem, 3vw, 16rem)',
  },
  image: {
    margin: '1rem',
    height: 'clamp(1.5rem, 3vw, 16rem)',
    width: 'clamp(1.5rem, 3vw, 16rem) !important',
  },
  [AirQuality.GOOD]: {
    fontSize: 'clamp(1.5rem, 3vw, 16rem)',
    color: Color.airQualityGood,
  },
  [AirQuality.MODERATE]: {
    fontSize: 'clamp(1.5rem, 3vw, 16rem)',
    color: Color.airQualityModerate,
  },
  [AirQuality.BAD]: {
    fontSize: 'clamp(1.5rem, 3vw, 16rem)',
    color: Color.airQualityBad,
  },
  recommendation: {
    display: 'flex',
    margin: '1rem',
    width: 'fit-content',
    color: Color.white,
    background: Color.kpOrange,
    fontSize: 'clamp(1.5rem, 3vw, 16rem)',
    textTransform: 'uppercase',
  },
  chart: {
    display: 'flex',
    width: 'calc(100% - 35rem)',
    overflow: 'hidden',
    height: '90%',
    flexGrow: 1,
  },
});

type AirQualitySlideProps = {
  co2Id: string;
  peopleCounterId: string;
};

export const AirQualitySlide: React.FC<AirQualitySlideProps> = ({
  co2Id,
  peopleCounterId,
}) => {
  const classes = useStyles();

  const { data: co2 } = useSensorData(co2Id);
  const { data: aggregatedData } = useAggregatedData(co2Id);
  // const { data: peopleCountValue } = useSensorData(peopleCounterId);

  // const peopleCount =
  //   peopleCountValue < 0 ? '-' : peopleCountValue.toFixed(0).toString();
  const airQuality = useMemo(() => {
    const value = Math.min(2000, co2 || 0);
    if (value > 1200) {
      return AirQuality.BAD;
    }
    if (value > 1000) {
      return AirQuality.MODERATE;
    }
    return AirQuality.GOOD;
  }, [co2]);

  const data = (aggregatedData || []).map(
    ({ utcTimeMeasured, valueString }) => ({
      x: new Date(utcTimeMeasured),
      y: parseInt(valueString, 10),
    }),
  );

  return (
    <div className={classes.root}>
      <div className={classes.leftColumn}>
        <Card
          title="CO₂ Sättigung"
          className={classes.leftCard}
          titleClassName={classes.cardTitle}
        >
          <div className={classes.quality}>
            <div className={classNames(classes.row, classes.paddingBottom)}>
              <div className={classes.value}>{co2}</div>ppm
            </div>
            <div className={classNames(classes.row, classes.center)}>
              <img
                src={presentation[airQuality].icon}
                alt="quality"
                className={classes.image}
              />
              <div className={classes[airQuality]}>
                {presentation[airQuality].text}
              </div>
            </div>
          </div>
        </Card>
        <Card
          title="Empfehlung"
          className={classNames(classes.leftCard, classes.leftMiddleCard)}
          titleClassName={classes.cardTitle}
        >
          <div className={classNames(classes.container, classes.center)}>
            <div className={classes.recommendation}>
              {presentation[airQuality].recommendation}
            </div>
          </div>
        </Card>
        <Card
          title="Personenzählung"
          className={classes.leftCard}
          titleClassName={classes.cardTitle}
        >
          <div
            className={classNames(
              classes.container,
              classes.row,
              classes.center,
              classes.spaceBetween,
            )}
          >
            <FaUserFriends size="3rem" className={classes.icon} />
            <div className={classes.value}>-</div>
            {/* Removed until the Hardware + BE are ready:
            <div className={classes.value}>{peopleCount}</div> */}
          </div>
        </Card>
      </div>
      <div className={classes.rightColumn}>
        <Card
          title="CO₂ Verlauf der letzten Stunde"
          className={classes.rightCard}
          titleClassName={classes.cardTitle}
        >
          <div className={classes.container}>
            <div className={classes.chart}>
              <Bar
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  scales: {
                    x: {
                      type: 'time',
                      time: {
                        displayFormats: {
                          minute: 'p',
                        },
                        unit: 'minute',
                        stepSize: 15,
                      },
                      adapters: {
                        date: {
                          locale: de,
                        },
                      },
                      grid: {
                        display: true,
                        drawOnChartArea: false,
                      },
                    },
                  },
                  plugins: {
                    legend: {
                      display: false,
                    },
                    title: {
                      display: false,
                    },
                  },
                }}
                data={{
                  datasets: [
                    {
                      label: 'CO2',
                      data,
                      backgroundColor: [
                        Color.grey,
                        Color.grey,
                        Color.grey,
                        Color.grey,
                        Color.grey,
                      ],
                    },
                  ],
                }}
              />
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};
