import React from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { slideHeight, slideWidth } from '../../constants';

const useStyles = createUseStyles({
  root: {
    height: slideHeight,
    width: slideWidth,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

interface SlideProps {
  className?: string;
  duration?: number;
  children?: React.ReactNode;
}

export const Slide: React.FC<SlideProps> = ({ children, className }) => {
  const classes = useStyles();
  return <div className={classNames(classes.root, className)}>{children}</div>;
};
