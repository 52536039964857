import React from 'react';
import { createUseStyles } from 'react-jss';
import { format } from 'date-fns';
import { WiSunrise, WiSunset } from 'react-icons/wi';
import classNames from 'classnames';
import { Card } from '../../components/Card';
import { Color } from '../../constants';

const useStyles = createUseStyles({
  card: {
    display: 'flex',
    width: '100%',
    height: '100%',
    padding: '1.5vw',
    background: Color.white,
  },
  cardTitle: {
    fontSize: 'clamp(1.5rem, 2vw, 16rem)',
    textAlign: 'left',
    padding: 10,
  },
  container: {
    display: 'flex',
    height: '100%',
    padding: '1.5vw',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 'clamp(0.5rem, 4vw, 16rem)',
  },
  durationColumn: {
    '& img': {
      marginTop: '-2vw',
      marginBottom: '0.3vw',
    },
    fontSize: 'clamp(0.5rem, 3vw, 13rem)',
    width: 'clamp(5rem, 8vw, 24rem)',
  },
  durationText: {
    width: 'min-content',
  },
  secondaryIcon: {
    height: 'clamp(1.0rem, 8vw, 12rem)',
    width: 'clamp(1.0rem, 8vw, 12rem)',
  },
  moonPhaseIcon: {
    height: 'clamp(1.6rem, 11vw, 18rem)',
    width: 'clamp(1.6rem, 11vw, 18rem)',
  },
});

export type SunMoonData = {
  sunrise: number;
  sunset: number;
  moonPhase: number;
};

type SunMoonCardProps = {
  sunMoonData: SunMoonData;
};

export const SunMoonCard: React.FC<SunMoonCardProps> = ({ sunMoonData }) => {
  const classes = useStyles();
  const { sunrise, sunset } = sunMoonData;

  return (
    <Card
      title="Sonne & Mond"
      className={classes.card}
      titleClassName={classes.cardTitle}
    >
      <div className={classes.container}>
        <div className={classes.column}>
          <WiSunrise className={classes.secondaryIcon} />
          {`${format(new Date(sunrise * 1000), 'kk:mm')}`}
        </div>
        <div className={classNames(classes.column, classes.durationColumn)}>
          <img src="./svg/sun-trajectory.svg" alt="sun-duration" />
          <div className={classes.durationText}>
            {`${Math.trunc((sunset - sunrise) / 3600)}h
        ${Math.trunc(((sunset - sunrise) % 3600) / 60)}m`}
          </div>
        </div>
        <div className={classes.column}>
          <WiSunset className={classes.secondaryIcon} />
          {`${format(new Date(sunset * 1000), 'kk:mm')}`}
        </div>
      </div>
    </Card>
  );
};
